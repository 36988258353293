<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate"
        >新增对战</el-button
      >
      <el-input
        v-model="listQuery.team_name"
        placeholder="请输入战队名称"
        style="width: 150px"
        class="filter-item"
        clearable
      />
      比赛时间：
      <el-date-picker
        v-model="dateArr"
        type="daterange"
        align="right"
        value-format="yyyy-MM-dd"
        unlink-panels
        range-separator="至"
        start-placeholder="比赛开始日期"
        end-placeholder="比赛结束日期"
        class="filter-item"
        style="display: inline-flex"
      ></el-date-picker>
      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="队伍A" min-width="110" align="center">
        <template slot-scope="scope"
          >{{
            scope.row.team_a ? JSON.parse(scope.row.team_a.team_snap).name : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column label="队伍B" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.team_b ? JSON.parse(scope.row.team_b.team_snap).name : "-"
          }}</template
        >
      </el-table-column>
      <el-table-column label="比赛时间" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.match_at }} </template>
      </el-table-column>
      <el-table-column label="胜者" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.win ? JSON.parse(scope.row.win.team_snap).name : "-"
          }}</template
        >
      </el-table-column>
      <el-table-column label="比分" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.team_a_score }} /{{ scope.row.team_b_score }}</template
        >
      </el-table-column>
      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <!-- <el-button size="mini" type="primary" @click="handleResult(scope.row)">比赛结果</el-button> -->
          <el-button size="mini" type="primary" @click="handleEdit(scope.row)"
            >比赛结果</el-button
          >
          <el-button size="mini" type="danger" @click="handleDel(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      :title="textMap[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="队伍A" prop="team_a_id">
          <el-select
            v-model="form.team_a_id"
            placeholder="比赛队伍A"
            clearable
            style="width: 150px; margin-right: 50px"
            class="filter-item"
          >
            <el-option
              v-for="v in teams"
              :label="JSON.parse(v.team_snap).name"
              :value="v.id"
              :key="v.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="队伍B" prop="team_b_id">
          <el-select
            v-model="form.team_b_id"
            placeholder="比赛队伍B"
            clearable
            style="width: 150px; margin-right: 50px"
            class="filter-item"
          >
            <el-option
              v-for="v in teams"
              :label="JSON.parse(v.team_snap).name"
              :value="v.id"
              :key="v.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="比赛时间" prop="match_at">
          <el-date-picker
            v-model="form.match_at"
            value-format="yyyy-MM-dd HH:mm:00"
            type="datetime"
            format='yyyy-MM-dd HH:mm'
            default-value
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >提交</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="比赛结果"
      :visible.sync="resultVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="formEnd"
        :rules="rulesEnd"
        :model="formEnd"
        label-width="120px"
      >
        <el-form-item label="胜者" prop="win_team_id">
          <el-select
            v-model="formEnd.win_team_id"
            placeholder="胜者"
            clearable
            style="width: 150px; margin-right: 50px"
            class="filter-item"
          >
            <el-option
              v-for="v in winTeam"
              :label="JSON.parse(v.team_snap).name"
              :value="v.id"
              :key="v.id"
            />
          </el-select>
        </el-form-item>
        <template v-if="winTeam.length > 0">
          <el-form-item
            :label="JSON.parse(winTeam[0].team_snap).name"
            prop="team_a_score"
          >
            <el-input type="number" v-model="formEnd.team_a_score" clearable />
          </el-form-item>
          <el-form-item
            :label="JSON.parse(winTeam[1].team_snap).name"
            prop="team_b_score"
          >
            <el-input type="number" v-model="formEnd.team_b_score" clearable />
          </el-form-item>
        </template>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resultVisible = false">取消</el-button>
        <el-button type="primary" @click="gameResult" :loading="btnLoading"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import uploadOne from "@/components/UploadOne/index.vue";
export default {
  components: {
    uploadOne,
  },
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 10,
        match_system_id: "",
        match_id: null,
        team_name: null,
        start_time: null,
        end_time: null,
      },
      form: {
        id: "",
        match_system_id: "",
        team_a_id: "",
        team_b_id: "",
        match_at: "",
      },
      formEnd: {
        id: "",
        win_team_id: "",
        team_a_score: "",
        team_b_score: "",
      },
      teams: [],
      winTeam: [],
      dateArr: [],
      listLoading: false,
      list: [],
      total: null,
      dialogFormVisible: false,
      resultVisible: false,
      dialogStatus: "",
      btnLoading: false,
      resultDialog: false,
      textMap: {
        create: "添加",
        update: "比赛结果录入",
      },
      rules: {
        id: [{ required: true, message: "不能为空", trigger: "change" }],
        team_a_id: [
          { required: true, message: "队伍A不能为空", trigger: "change" },
        ],
        team_b_id: [
          { required: true, message: "队伍B不能为空", trigger: "change" },
        ],
        match_at: [
          { required: true, message: "比赛时间不能为空", trigger: "change" },
        ],
      },
      rulesEnd: {
        win_team_id: [
          { required: true, message: "请选择胜利队伍", trigger: "change" },
        ],
        team_a_score: [
          { required: true, message: "比分不能为空", trigger: "change" },
        ],
        team_b_score: [
          { required: true, message: "比分不能为空", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    dateArr(newVal, oldVal) {
      if (newVal) {
        this.listQuery.start_at = newVal[0];
        this.listQuery.end_at = newVal[1];
      } else {
        this.listQuery.start_at = "";
        this.listQuery.end_at = "";
      }
    },
  },
  created() {
    this.listQuery.match_id = this.form.match_id = this.$route.query.match_id;
    this.listQuery.match_system_id = this.form.match_system_id =
      this.$route.query.id;
    this.getTeamList();
    this.getList();
  },
  methods: {
    handleDel(val) {
      this.$confirm("此操作将删除该对战, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/schoolend/match/arrangementDel",
            method: "post",
            params: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    getTeamList() {
      request({
        url: "/api/schoolend/match/rankingList",
        method: "get",
        params: {
          match_id: this.listQuery.match_id,
        },
      }).then((response) => {
        this.teams = response.data;
      });
    },
    handleResult() {
      this.resultDialog = true;
    },
    gameResult() {
      this.$refs["formEnd"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/schoolend/match/matchResultStore",
            method: "post",
            data: this.formEnd,
          })
            .then((response) => {
              this.btnLoading = false;
              this.resultVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    saveData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/schoolend/match/arrangementStore",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    saveResult() {
      this.$refs["result"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/schoolend/course/lecturerStore",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/schoolend/match/arrangementList",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleEdit(row) {
      console.log(row);
      // this.resetForm();
      this.winTeam = [];
      // this.form = Object.assign({}, row);
      this.winTeam.push(row.team_a, row.team_b);
      console.log(this.winTeam);
      if (row.win) {
        this.formEnd = {
          id: "",
          win_team_id: row.win.id,
          team_a_score: row.team_a_score,
          team_b_score: row.team_b_score,
        };
      } else {
        this.formEnd = {
          id: "",
          win_team_id: "",
          team_a_score: "",
          team_b_score: "",
        };
      }
      this.formEnd.id = row.id;
      this.resultVisible = true;

      // this.$nextTick(() => {
      //   this.$refs["form"].clearValidate();
      // });
    },
    handleCreate() {
      this.resetForm();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    resetForm() {
      this.form = {
        id: "",
        match_system_id: this.form.match_system_id,
        team_a_id: "",
        team_b_id: "",
        match_at: "",
      };
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.cell {
  padding: 10px 15px;
  display: flex;
  border: 1px solid #4c4c4c;
}
</style>
